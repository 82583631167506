
iframe {
    display: none !important;
}

@font-face {
	font-family: 'BNR'; 
	src: url(../src/fonts/bnr.ttf); 
}

@font-face {
	font-family: 'Manrope-ExtraLight'; 
	src: url(../src/fonts/Manrope-ExtraLight.ttf); 
}

@font-face {
	font-family: 'Manrope-Light'; 
	src: url(../src/fonts/Manrope-Light.ttf); 
}

@font-face {
	font-family: 'Manrope-Regular'; 
	src: url(../src/fonts/Manrope-Regular.ttf); 
}

@font-face {
	font-family: 'Manrope-Medium'; 
	src: url(../src/fonts/Manrope-Medium.ttf); 
}

@font-face {
	font-family: 'Manrope-Semibold'; 
	src: url(../src/fonts/Manrope-SemiBold.ttf); 
}

@font-face {
	font-family: 'Manrope-Bold'; 
	src: url(../src/fonts/Manrope-Bold.ttf); 
}

@font-face {
	font-family: 'Manrope-Extrabold'; 
	src: url(../src/fonts/Manrope-ExtraBold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Light'; 
	src: url(../src/fonts/Montserrat-Light.ttf); 
}

@font-face {
	font-family: 'Montserrat-Regular'; 
	src: url(../src/fonts/Montserrat-Regular.ttf); 
}

@font-face {
	font-family: 'Montserrat-Medium'; 
	src: url(../src/fonts/Montserrat-Medium.ttf); 
}

@font-face {
	font-family: 'Montserrat-Semibold'; 
	src: url(../src/fonts/Montserrat-Semibold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Bold'; 
	src: url(../src/fonts/Montserrat-Bold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Extrabold'; 
	src: url(../src/fonts/Montserrat-Extrabold.ttf); 
}

body, body * {
    font-family: "Montserrat-Light", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

.logo {
    height: 50px;
    margin: 11px 8px 11px 8px;
    background: url(../src/img/logo.png) no-repeat center center;
    background-size: contain;
}

.profile_name {
    color: #ffffff;
    margin: 10px;
    text-align: center;
    overflow: hidden;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: transparent;
    border-radius: 30px;
    min-height: 200px;
}

.cardnone {
    background: none!important;
    box-shadow: none!important;
}

.bordershadow {
    padding: 30px;
    border-radius: 30px;
    background: #ffffff;
    box-shadow: 0 4px 30px 0 rgb(0 0 0 / 20%);
}

.ant-form-item:last-child {
    margin-bottom: 0;
}

.wrow {
    display: flex;
    flex-wrap: wrap;
}

.wcol {
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    min-width: 300px;
    flex: 1 1 calc(100%/4 - 4rem);
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.wcol > .ant-badge {
    height: 100%;
}

.maxtask {
    margin-top: -20px;
}

.colors {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    max-width: 150px;
}

.colors > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 calc(100%/4 - 2px);
    margin: 5px 0;
}

.maxtask:before{
    content:"";
    display:block;
    height:30px;
    position:relative;
    top:180px;
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7 75%)
}

.maxtask::after {
    content: "";
    height: 100%;
    width: 100%;
    background: transparent;
    position: absolute;
    z-index: 2;
    padding: 20px;
    top: 0;
    left: 0;
}

.c1.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7 75%)}
.c2.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffc9c5 75%)}
.c3.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffeaad 75%)}
.c4.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #fffbcb 75%)}
.c5.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #e4ffc4 75%)}
.c6.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d7fff6 75%)}
.c7.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d8f8ff 75%)}
.c8.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #c7dcff 75%)}
.c9.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ead2ff 75%)}
.c10.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffe1f1 75%)}
.c11.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffedd8 75%)}
.c12.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #e8eaed 75%)}
.c13.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #6fee99 75%)}
.c14.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d1ae93 75%)}

.ant-layout, .ant-layout * {
    overflow: visible;
}

.lasttask {
    padding: 0 20px 20px 20px;
    margin-top: auto;
    margin-bottom: 0 !important;
}

.maxtask {
    max-height: 200px;
    overflow: hidden;
    height: 100%;
}

.fcenter {
    text-align: center;
    display: flex;
    justify-content: center;
}

.image_radius {
    border-radius: 1000px;
    overflow: hidden;
}

.tworow {
    display: flex;
    flex-wrap: wrap;
}

.tworow > div {
    width: 50%;
    min-width: 50%;
    padding: 20px;
}

.hover_list .ant-list-item {
    cursor: pointer;
    transition: all .3s ease;
}

.hover_list .ant-list-item:hover {
    transform: scale(1.01);
}

.ant-modal-confirm-body > .anticon-info-circle {
    display: none;
}

.ant-modal-confirm-body > .ant-modal-confirm-content {
    margin-left: 0 !important;
}

.center_footer .ant-modal-confirm-btns {
    float: none;
    text-align: center;
}

.pulse {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    color:gray;
    background: white;
    border-radius: 50%;
    animation: radial-pulse 1.5s infinite;
}

@keyframes radial-pulse {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }

    100% {
        box-shadow: 0 0 0 40px rgba(0, 0, 0, 0);
    }
}

.listfull {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.listnofull {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
    visibility: visible;
    z-index: 99999999;
    list-style-type: none;
}

.listnofull>div:nth-child(1) {
    margin-right: 10px;
}

.listnofull>div:nth-child(2) {
    margin-right: 10px;
}

.listnofull>div:nth-child(4) {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
}

.w100 > .ant-space-item:nth-child(3) {
    width: 100%;
}

.theme {
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #444444;
}

.taskheader {
    padding: 20px 0;
    display: flex;
}

.tasktop {
    z-index: 100;
    margin-left: auto;
}

.elementlock ~ * {
    z-index: 3;
}

.cardlist {
    align-items: flex-start;
}

.cardlist .ant-card {
    height: 100%;
}

.cardlist > * {
    width: 300px;
}

.ant-table {
    background: transparent;
}

.elementlock > * {
    padding-left: 20px;
    padding-right: 20px;
}

.taskhide {
    opacity: .8;
    padding: 0;
    cursor: pointer;
    transition: all .3s ease;
    min-height: 200px;
}

.taskhide:first-of-type:last-child {
    max-width: 500px;
}

.taskhide:nth-child(4n+1):last-child {
    max-width: 500px;
}

.ant-table {
    background: transparent;
}

.ant-card-meta-title {
    white-space: pre-wrap;
}

.ant-image-img {
    object-fit: contain;
}

.filelist .ant-col, .filelist .ant-col, .filelist .ant-col > .ant-list-item, .filelist .ant-col > .ant-list-item > .ant-card {
    height: 100%;
}

.filelist .ant-row {
    justify-content: center;
    align-items: stretch;
}

.filelist .ant-row > div {
    margin-bottom: 20px;
}

.animdown > div {
    animation: carding 1s ease;
}

.taskhide:hover {
    -webkit-box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    transform: scale(1.01);
}

.ant-popover-inner {
    border-radius: 15px;
}

.shadowscreen {
    -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    background: #f7f7f7;
    border-radius: 30px;
}

[data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
}

.login-form-button {
    width: 100%;
}

.authform {
    justify-content: center;
}

.authpage {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadpage {
    justify-content: center;
}

.bg-auth {
    background: url(../src/img/bg.png) fixed no-repeat center center;
    background-size: cover !important;
}

.buttmar > button {
    margin-right: 8px;
    margin-bottom: 12px;
}

.ant-page-header {
    padding: 16px 24px 16px 74px;
}

.menuleft .ant-drawer-content {
    background: rgb(66,71,139);
    background: linear-gradient(323deg, rgba(66,71,139,1) 0%, rgba(55,112,129,1) 100%);
}

.ant-menu {
    color: #ffffff;
}

.ant-menu-item a {
    color: #ffffff;
}

.ant-drawer-body {
    padding: 0;
}

.ant-menu-item .ant-menu-item-icon{
    background: #024d7900;
    padding: 6px;
    border-radius: 50px;
    transition: all .3s ease;
}

.ant-menu-item-active .ant-menu-item-icon {
    background: #024e79;
    color: #ffffff;
}

.stats_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.stats_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 200px;
    margin: 10px;
    padding: 20px;
}

.stats_title {
    font-family: 'Montserrat-Bold';
    text-align: center;
    margin-bottom: 10px;
}

.stats_count {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.stats_value {
    color: #ffffff;
    font-family: 'Montserrat-Bold';
    padding: 2px 8px;
    background: rgb(83, 196, 115);
    border-radius: 50px;
}

.stats_value_all {
    margin: auto;
    color: #ffffff;
    font-family: 'Montserrat-Bold';
    padding: 2px 8px;
    background: rgb(83, 196, 115);
    border-radius: 50px;
}

.stats_percent {
    margin-top: 20px;
    color: #ffffff;
    font-family: 'Montserrat-Bold';
    padding: 2px 8px;
    background: #714d93;
    border-radius: 50px;
}

.stats_percent::after {
    
    content: "%";
}

.floattask {
    display: flex;
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 1;
}

.filtersvisible {
    position: absolute;
    opacity: 0;
    top: -1000px;
    width: 0;
    height: 0;
}

.filtersmargin {
    margin-right: 16px;
}

.ant-btn-primary {
    border-radius: 30px;
}

.ant-btn-dashed {
    border-radius: 30px;
}

.whitebut .ant-btn:hover, .ant-btn:focus {
    color: #000000;
}

.inputtrans .ant-btn {
    border-color: #ffffff;
    width: 22px;
    height: 22px;
    min-width: 22px;
}

.bluebut .ant-btn {
    background: #024e79 !important;
    color: #ffffff;
}

.bluebut * {
    border-color: #024e79 !important;
}

.bluebut .ant-btn:hover, .bluebut .ant-btn:focus {
    color: #ffffff;
}

.des-button {
    background: rgb(58 137 216);
    background: linear-gradient(180deg, rgb(58 137 216) 0%, rgb(37 82 147) 100%);
    color: #ffffff;
    border: none;
    box-shadow: 0 0px 15px 0 rgb(0 0 0 / 20%);
}

.des-button > * {
    font-family: 'Montserrat-Regular' !important;
}

.des-button.ant-btn-dangerous {
    background: rgb(240, 160, 160);
    background: linear-gradient(180deg, rgb(240, 160, 160) 0%, rgb(190, 78, 78) 100%);
}

.des-green {
    background: rgb(115, 142, 94);
    background: linear-gradient(165deg, rgb(115, 142, 94) 0%, rgb(91, 115, 72) 100%);
}

.des-button:hover, .des-button:focus {
    background: linear-gradient(165deg, rgb(42, 98, 135) 0%, rgb(71, 95, 140)100%) !important;
    color: #ffffff !important;
}

.des-green:hover, .des-green:focus {
    background: linear-gradient(165deg, rgb(99, 124, 81) 0%, rgb(83, 104, 65)100%) !important;
    color: #ffffff !important;
}

.des-button.ant-btn-dangerous:hover, .des-button.ant-btn-dangerous:focus {
    background: linear-gradient(180deg, rgb(216, 132, 132) 0%, rgb(163, 62, 62) 100%) !important;
    color: #ffffff !important;
}

.ant-btn:hover, .ant-btn:focus {
    color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
}

.ant-btn-dashed:active {
    color: rgb(0, 0, 0);
    background: #fff;
    border-color: rgb(0, 0, 0);
}

.ant-btn-dashed:hover, .ant-btn-dashed:focus {
    color: rgb(0, 0, 0);
    background: #fff;
    border-color: rgb(0, 0, 0);
}

.site_pay {
    display: flex;
}

.site_pay_item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    border-radius: 50px;
    width: 24px;
    height: 24px;
}

.shover {
    transition: all .3s ease;
    cursor: pointer;
}

.shover:hover {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
}

.modal-mini {
    max-width: calc(100vw - 10%);
}

.modal-mini .ant-modal-header {
    display: none;
}

.modal-mini > .ant-modal-content {
    box-shadow: 0 4px 30px 0 rgb(0 0 0 / 30%);
    background: rgb(220,224,250);
    background: linear-gradient(0deg, rgba(220,224,250,1) 0%, rgba(229,232,242,1) 20%, rgba(229,232,242,1) 80%, rgba(196,200,228,1) 100%);
    background-position-x: right;
}

.modal-mini .ant-form-item-label > label {
    color: #355b7d !important;
    font-family: 'Montserrat-Semibold' !important;
}

.modal-mini-header {
    padding-top: 20px;
    text-align: center;
}

.modal-mini-body {
    padding: 24px;
}

.modal-mini  .ant-modal-body {
    padding: 0;
}

.modal-mini-header::after {
    content: "";
    display: block;
    height: 1px;
    background: #97bee1;
    margin-bottom: 30px;
}

.modal-mini-header-title {
    color: #355b7d !important;
    font-family: 'Montserrat-Semibold' !important;
    font-size: 16px;
    margin: 10px;
}

.modal-mini-header-title.title-big {
    font-size: 22px;
}

.modal-mini .ant-list-item strong {
    color: #355b7d
}

.modal-mini .ant-input-affix-wrapper, .modal-mini .ant-input-affix-wrapper, .modal-mini .ant-picker, .modal-mini .ant-select:not(.ant-select-customize-input) .ant-select-selector, .modal-mini .ant-form-item-control-input-content > .ant-input, .modal-mini textarea.ant-input {
    border: 1px solid #97bee1 !important;
    -webkit-box-shadow: 1px -1px 5px 0px rgba(34, 60, 80, 0.15) inset;
    -moz-box-shadow: 1px -1px 5px 0px rgba(34, 60, 80, 0.15) inset;
    box-shadow: 1px -1px 5px 0px rgba(34, 60, 80, 0.15) inset;
}

.modal_new > .ant-modal-content{
    background: #ffffff !important;
}

.modal_newtitle {
    font-family: 'Montserrat-Bold' !important;
    font-size: 16px;
    text-align: center;
    color: #024e79;
    margin-bottom: 20px;
}

.margintopbottom {
    margin-top: 5px;
    margin-bottom: 5px;
}

.menubutton {
    position: fixed;
    display: flex;
    left: 0;
    top: 16px;
    left: 16px;
    font-size: 18px;
    width: 40px;
    height: 40px;
    box-shadow: 5px 0 5px 0 rgb(0 0 0 / 15%);
    border-radius: 8px;
    background: #9aaccc;
    color: #ffffff;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.ant-drawer-content-wrapper {
    will-change: transform;
}

@media screen and (max-width: 1100px) {

    .tworow {
        flex-direction: column;
    }

    .tworow > div {
        width: 100%;
        margin-bottom: 50px;
    }

    .tworow > div:last-of-type {
        margin-bottom: unset;
    }

    .filtershidden {
        display: none;
    }
    .filtersvisible {
        position: unset;
        opacity: 1;
        top: unset;
        width: unset;
        height: unset;
    }
    .filtersmargin {
        margin-right: 0;
    }
}

@media screen and (max-width: 700px) {
    .space_input {
        flex-direction: column;
    }

    .space_input .ant-space-item, .space_input .ant-select {
        width: 100% !important;
    }
}

@media screen and (max-width: 650px) {

    .wcol:last-child:first-child {
        max-width: 100%;
    }
    .wcol {
        margin: 20px 0;
    }

    .bg-auth {
        background: url(../src/img/bgm.png) fixed no-repeat center center;
    }
    
}

@media screen and (max-width: 600px) {

    .stats_list {
        flex-direction: column;
    }
    
    .stats_item {
        max-width: 100%;
        margin: 0 0 20px 0;
    }

    .stats_item:last-of-type {
        margin: 0;
    }

    .usercard {
        flex-direction: column !important;
    }

    .usercard > div:first-child {
        display: flex;
        justify-content: center;
    }

    .usercard > div {
        margin: 10px 0 !important;
    }
    
}

@media screen and (max-width: 500px) {
    .wcol {
        min-width: 100%;
    }
}


.gradient {
	color: transparent;    
	-webkit-background-clip: text;
	background-clip: text;
	background-image: linear-gradient(135deg, #0daaeb, #9054cb);
}

.auth_remember span {
    color: #FFFFFF !important;
    background-color: none !important;
}

.authpageblock {
    padding: 24px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.authtitle {
    font-family: 'BNR';
    text-align: center;
    color: #FFFFFF;
}

.authtitle p:nth-child(2) {
    font-size: 35px;
    margin: 0 !important;
    padding: 0 !important;
}

.authtitle p:nth-child(3) {
    font-size: 60px;
    line-height: 40px;
}

.inauth {
    border-radius: 25px;
    height: 40px;
    color: #8a8a8a;
}

.inauths {
    border-radius: 25px;
}

.tableimg {
    max-width: 50px;
}

.tableimg > img {
    width: 100%;
}

.spincenter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.alcenter {
    align-items: unset;
}

.form800 {
    max-width: 800px;
}

.ant-avatar-circle > img {
    border-radius: 50%;
}

.searchinput {
    max-width: 200px;
    display: flex;
    align-items: center;
    margin-left: auto;
}

.searchinput > input {
    color: #FFFFFF;
    border-color: unset !important;
    background: transparent;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
    box-shadow: none !important;
}

.buttmar {
    margin-top: 10px;
}

.searchinput .ant-form-item {
    margin-bottom: 0;
}

.ant-tabs-tab-disabled {
    display: none;
}

.ant-tabs-tab-active {
    display: block;
}

.toh {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    margin-left: 5px;
}

.ant-space-vertical {
    width: 100%;
}

.alcenter {
    width: 100%;
    display: inline-flex;
}

.usercard {
    display: flex;
    flex-direction: row;
}

.usercard > div {
    flex: 0 1;
    margin: 10px;
}

.usercard > div:last-child {
    flex: 1 0;
    padding: 24px 40px;
    background-color: #f0f2f5;
}

.usercard>div:first-child {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}

.usercard>.trans:last-child {
    background-color: transparent;
}

.ant-layout {
    margin-bottom: 20px;
    z-index: 1;
}

.ant-input, .ant-input-affix-wrapper, .ant-select-multiple .ant-select-selector, .ant-select-single .ant-select-selector, .ant-input-number, .ant-picker, .ant-modal-content, .ant-popover-buttons button {
    border-radius: 30px !important;
}

.ant-modal-header {
    border-radius: 30px 30px 0 0;
}

.ant-layout-sider-zero-width-trigger {
    box-shadow: 5px 0 5px 0 rgb(0 0 0 / 15%);
    border-radius: 0 4px 4px 0;
    background: #FFFFFF;
    color: #000000;
    top: 80px;
    z-index: 2;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-item:active, .ant-menu-submenu-title:active {
    background-color: transparent;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    display: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 10px;
    border-radius: 20px 0 0 20px;
    background-color: #ffffff28;
    content: '';
}

.ant-menu-submenu-selected {
    color: #000000;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: #ffffff;
}

li.ant-menu-item > span {
    position: relative;
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover, .ant-menu-item a:hover, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #cccccc;
}

a.ant-typography, .ant-typography a {
    color: rgba(0, 0, 0, 0.85);
}

.ant-list-item-meta-title > a:hover {
    color: rgba(48, 48, 48, 0.85);
}

a.ant-typography:active, .ant-typography a:active {
    color: rgba(32, 32, 32, 0.85);
}

.ant-menu {
    background: transparent;
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    width: 100% !important;
}

.ant-menu-item {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 40px !important;
    padding-right: 0 !important;
    overflow: visible !important;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0;
    margin-bottom: 0;
}

.ant-menu-sub.ant-menu-inline {
    background: transparent;
}

.ant-menu-item > span {
    flex: 0 1;
}

.ant-menu-item > span:last-child {
    flex: 1 0;
}

.ant-menu-item > span:last-child::before {
    width: 20px;
    height: 20px;
    right: 0;
    top: -20px;
    display: none;
    position: absolute;
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Слой_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23f0f2f5;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M20,20H0c11,0,20-9,20-20V20z'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat;
    content: "";
    opacity: .2;
}

.ant-menu-item > span:last-child::after {
    width: 20px;
    height: 20px;
    right: 0;
    bottom: -20px;
    display: none;
    position: absolute;
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Слой_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23f0f2f5;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M20,0v20C20,9,11,0,0,0H20z'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat;
    content: "";
    opacity: .2;
}

.ant-menu-item-selected:not(:first-child) > span:last-child::before, .ant-menu-item-selected > span:last-child::after {
    display: inline-block;
}


.dividermenu {
    margin: 10px 25px;
    background: #FFFFFF;
    min-width: auto;
    width: auto;
}

@keyframes carding {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #c797ec;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #a2bbd16c;
}